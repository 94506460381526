
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import TopViewTable from "@views/page/methods-of-logs/components/top-view-table.vue";
import moment from "moment";
import FilterModel from "../../components/filter-model";

import TopEndpointLogItemExtends from "../models/TopEndpointLogItemExtends";
import getUTC from "@/function/getUTC"
import EndpointLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse";
import EndpointStatisticItem from "@/dataSource/api/logger-micro-service/services/models/response/top-endpoint-logs-service/EndpointStatisticItem";
import GetTopEndpointLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/top-endpoint-logs-service/GetTopEndpointLogsResponse";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import TopEndpointLogItem from "@/dataSource/api/logger-micro-service/services/models/response/top-endpoint-logs-service/TopEndpointLogItem";
import GetEndpointStatisticsResponse from "@/dataSource/api/logger-micro-service/services/models/response/top-endpoint-logs-service/GetEndpointStatisticsResponse";
import GetTopEndpointLogsRequest from "@/dataSource/api/logger-micro-service/services/models/request/top-endpoint-logs-service/GetTopEndpointLogsRequest";

@Options({
  name: "TopHeightView",
  components: {
    TableView,
    TopViewTable,
  },
})
export default class TopHeightView extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;
  innerFilter: FilterModel = new FilterModel();
  items: EndpointLogsItemResponse[] = [];
  itemsStat: EndpointStatisticItem[] = []; //статистика методов
  itemTopReqTimeExtends: EndpointStatisticItem = null;

  itemsTopReqTime: GetTopEndpointLogsResponse = null; //топ request
  itemsTopReqTimeExtends: TopEndpointLogItemExtends[] = [];
  topReqTimeExtendsPage: PageResponse = null;

  itemsTopReqVolume: GetTopEndpointLogsResponse = null; //топ request
  itemsTopReqVolumeExtends: TopEndpointLogItemExtends[] = [];
  itemsTopReqVolumeExtendsPage: PageResponse = null;

  itemsTopResp: GetTopEndpointLogsResponse = null; //топ response
  itemsTopRespExtends: TopEndpointLogItemExtends[] = [];
  itemsTopRespExtendsPage: PageResponse = null;

  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }
  error3 = {
    isSuccess: true,
    errorMessage: ""
  }
  error4 = {
    isSuccess: true,
    errorMessage: ""
  }

  endpointsIdArr: number[] = [];
  size = 10;
  // page: PageRequest = { Take: 10, Skip: 0 };
  count = 0;
  skip = 0;

  noPagination = false;
  isLoading = false;

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  onFilterChange() {
    if (this.$refs.pagination as any) {
      (this.$refs.pagination as any).reset();
    }
    this.onclickFilterChange();
  }

  async onclickFilterChange() {
    await this.getItemsTopReqTime(1);
    await this.getItemsTopReqValue(1);
    await this.getItemsTopResp(1);
    this.skip = 0
    if (this.endpointsIdArr.length > 0) {
      try {
        let res = await this.getItemsStat();
        console.log("ress=", res);
      } catch (error) {
        this.$notification.error(error.ErrorMessage);
        console.log("error=", error);
        return;
      }
    }
  }

  trim(value: string) {
    let rez = value && value.trim() === "" ? null : value;
    return rez;
  }

  mounted() {

  }

  addEnpoints(arr: TopEndpointLogItem[]) {
    arr.forEach((el) => {
      this.endpointsIdArr.push(el.EndpointId);
    });
  }

  async getItemsStat() {
    let unique = this.endpointsIdArr.filter((v, i, a) => a.indexOf(v) === i); // (item, index, arr)
    let response: GetEndpointStatisticsResponse = null;

    this.isLoading = true;

    const innerFilter1 = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.trim(this.filter.Ip),
      UserId: this.trim(this.filter.UserId),
      SessionToken: this.trim(this.filter.SessionToken),

      ConnectionId: this.trim(this.filter.ConnectionId),
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: this.skip, Take: 10},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response =
          await this.$api.TopEndpointLogsService.getCurrentEndpointsStatisticsAsync(
              {
                GetTopEndpointLogsRequest: tmpFilter,
                ForEndpointsIds: unique,
              }
          );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error4.isSuccess = false;
      this.error4.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsStat = response.Items; // GetEndpointStatisticsResponse.Items
    this.isLoading = false;
    this.count = response.Page.Count;

    this.itemsStat.forEach((item) => {
      this.itemsTopReqTimeExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });

      this.itemsTopReqVolumeExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });

      this.itemsTopRespExtends.forEach((el) => {
        if (item.EndpointId == el.EndpointId) {
          el.AverageResponsesSize = item.AverageResponsesSize;
          el.AverageRequestsSize = item.AverageRequestsSize;
          el.AverageExecutingTime = item.AverageExecutingTime;
        }
      });
    });

    return Promise.resolve("good");
  }

  onChangePageTopReqTime(page: number) {
    this.getItemsTopReqTime(page);
    
  }

  async getItemsTopReqTime(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)

    try {
      response = await this.$api.TopEndpointLogsService.getTopLongRequestsAsync(
          tmpFilter
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopReqTime = response;
    this.itemsTopReqTimeExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.topReqTimeExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  onChangePageTopReqValue(page: number) {
    this.getItemsTopReqValue(page);
   
  }

  async getItemsTopReqValue(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response = await this.$api.TopEndpointLogsService.getTopFatRequestsAsync(
          tmpFilter
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopReqVolume = response;
    this.itemsTopReqVolumeExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.itemsTopReqVolumeExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  onChangePageTopResp(page: number) {
    this.getItemsTopResp(page);
  }

  async getItemsTopResp(page: number = 1) {
    let response: GetTopEndpointLogsResponse = null;
    this.isLoading = true;
    const innerFilter1: GetTopEndpointLogsRequest = {
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.trim(this.filter.SessionToken),
      ConnectionId: this.filter.ConnectionId,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: (page - 1) * this.size, Take: this.size},
    };
    let tmpFilter = getUTC(innerFilter1)
    try {
      response = await this.$api.TopEndpointLogsService.getTopFatResponsesAsync(
          tmpFilter
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error3.isSuccess = false;
      this.error3.errorMessage = error;
      this.isLoading = false;
    }
    this.itemsTopResp = response;
    this.itemsTopRespExtends = response.Items.map((item) => {
      return {
        AverageExecutingTime: 0,
        SummaryExecutingTime: 0,
        AverageResponsesSize: 0,
        AverageRequestsSize: 0,
        DateCreate: item.DateCreate,
        ConnectionId: item.ConnectionId,
        RequestId: item.RequestId,
        RequestLength: item.RequestLength,
        SessionToken: item.SessionToken,
        EndpointId: item.EndpointId,
        LevelId: item.LevelId,
        Class: item.Class,
        Method: item.Method,
        ResponseLength: item.ResponseLength,
        ExecTimeMs: item.ExecTimeMs,
        UserId: item.UserId,
        Ip: item.Ip,
      };
    });

    this.itemsTopRespExtendsPage = response.Page;
    this.addEnpoints(response.Items);
    this.isLoading = false;
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.size;
    if (this.skip === skip) {
      return;
    }
    this.skip = skip;
    this.getItemsStat();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
}
